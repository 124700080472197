import React, { useState, useRef, useEffect } from 'react'
import { Input, Button } from '@cmd/library'

import { withContext } from '../../context/withContext'

import { httpClient } from '../../utils/httpClient'

import './OTP.css'

const OTP = ({ context: { loanPayload }, history }) => {
  const otpRef = useRef(null)
  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const [timer, setTimer] = useState(60)
  const [errMsg, setErrMsg] = useState(false);

  function handleChangeOTPInput (e, index) {
    e.preventDefault();
    const charCode = parseInt(e.target.value, 10);

    console.log(e.target.value)
    
    if (isFinite(charCode)) {
      const newSetOTP = otp
      newSetOTP[index] = e.target.value.charAt(e.target.value.length - 1);
      setOtp([...newSetOTP])
      const next = otpRef.current.querySelectorAll('.form--input')
      if (next[index + 1]) {
        next[index + 1].focus()
      }
    }
  }

  function onKeyUpOTP(e, index) {
    const key = e.keyCode || e.charCode
    const eventKey = e.key
    
    if (key === 8 || key === 46 || eventKey === 'Backspace' || eventKey === 'Delete') {
      const next = otpRef.current.querySelectorAll('.form--input')
      if (next[index - 1]) {
        const newSetOTP = otp
        newSetOTP[index] = ''
        setOtp([...newSetOTP])
        next[index - 1].focus()
      }
    }
  }

  function setMMSS (seconds) {
    const date = new Date(0)
    date.setSeconds(seconds)
    const timeString = date.toISOString().substr(14, 5)
    return timeString
  }

  async function validateOTP () {
    try {
      const { data } = await httpClient.post('/check_otp', {
        phone_number: loanPayload.phone_number,
        otp: otp.join(''),
      });

      if (data.Success) {
        history.push('/pinjaman/personal-information')
      } else {
        setErrMsg(data.message)
      }
    } catch (e) {
      console.error('Something went wrong', e)
      setErrMsg('Sedang ada kendala pada sistem. Silahkan coba beberapa saat lagi.');
    }
  }

  useEffect(() => {
    if (!timer) return

    const timeLeft = setInterval(() => {
      setTimer(timer - 1)
    }, 1000)

    return () => clearInterval(timeLeft)
  })

  return (
    <div>
      <div className="row">
        <div className="col">
          <p className="otp-info-text">
            Kami telah mengirimkan 6 digit OTP ke <strong>{loanPayload.phone_number}</strong>
          </p>
        </div>
      </div>
      <div className="otp-input-row" ref={otpRef}>
        { otp.map((o, index) => {
          return (
            <div className="otp-input-col" key={`otp-index-${index}`}>
              <div className="input-otp-wrapper">
                <input
                  type='tel'
                  onInput={(e) => handleChangeOTPInput(e, index)}
                  onFocus={(e) => e.target.select()}
                  onKeyUp={(e) => onKeyUpOTP(e, index)}
                  value={otp[index]}
                  className="form--input"
                />
              </div>
            </div>
          )
        }) }
      </div>
      <div className="row mb--1">
        <div className="col">
          { timer === 0 ? (
            <p className="text--size-12 text--color-gold cursor-pointer"><strong>Kirim ulang  OTP</strong></p>
          ) : (
            <p className="text--size-12 text--color-green"><strong>Kirim ulang  OTP dalam { setMMSS(timer) }</strong></p>
          ) }
        </div>
      </div>
      {
        errMsg && (
          <div className="row mb--1">
            <div className="col">
              <small style={{ color: 'red' }}>{errMsg}</small>
            </div>
          </div>
        )
      }
      <div className="fx">
        <div className="mr--2">
            <Button
              disabled={!otp.every(o => o !== '')}
              onClick={validateOTP}
              text="Lanjut"
            />
        </div>
        <div>
          <Button text="Batal" className="btn--accent" onClick={() => history.push('/pinjaman/contact')} />
        </div>
      </div>
    </div>
  )
}

export default withContext(OTP)
