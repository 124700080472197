"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.capitalizeFirstCharacter = exports.validatePhone = exports.validateEmail = exports.moneyToString = exports.rupiah = exports.splitThousand = void 0;

var splitThousand = function splitThousand(number) {
  if (!number && number !== 0) return "";
  return "".concat(number).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

exports.splitThousand = splitThousand;

var rupiah = function rupiah(integer) {
  if (!integer) return "Rp";
  return "Rp".concat(splitThousand(integer));
};

exports.rupiah = rupiah;

var moneyToString = function moneyToString(nominal) {
  var _isNominalValid = function _isNominalValid(nominal) {
    var regex = new RegExp(/^\d+$/);
    return !regex.test(nominal);
  };

  var _nominalWording = function _nominalWording(nominal) {
    var UNIQUE_TWELVE = 12;
    var UNIQUE_TWENTY = 20;
    var UNIQUE_TWO_HUNDRED = 200;
    var UNIQUE_TWO_THOUSAND = 2000;
    var UNIQUE_NAMING = ['', '1 ', '2 ', '3 ', '4 ', '5 ', '6 ', '7 ', '8 ', '9 ', '10 ', '11 ', '12 ', '13 ', '14 ', '15 ', '16 ', '17 ', '18 ', '19 ', '20 ', '21 ', '22 ', '23 ', '24 ', '25 ', '26 ', '27 ', '28 ', '29 ', '30 ', '31 ', '32 ', '33 ', '34 ', '35 ', '36 ', '37 ', '38 ', '39 ', '40 ', '41 ', '42 ', '43 ', '44 ', '45 ', '46 ', '47 ', '48 ', '49 ', '50 ', '51 ', '52 ', '53 ', '54 ', '55 ', '56 ', '57 ', '58 ', '59 ', '60 ', '61 ', '62 ', '63 ', '64 ', '65 ', '66 ', '67 ', '68 ', '69 ', '70 ', '71 ', '72 ', '73 ', '74 ', '75 ', '76 ', '77 ', '78 ', '79 ', '80 ', '81 ', '82 ', '83 ', '84 ', '85 ', '86 ', '87 ', '88 ', '89 ', '90 ', '91 ', '92 ', '93 ', '94 ', '95 ', '96 ', '97 ', '98 ', '99 '];
    var TEN = 10;
    var ONE_HUNDRED = 100;
    var ONE_THOUSAND = 1000;
    var ONE_MILLION = 1000000;
    var ONE_BILLION = 1000000000;

    if (nominal < ONE_HUNDRED) {
      // if (nominal < UNIQUE_TWELVE)
      return UNIQUE_NAMING[nominal]; // if (nominal < UNIQUE_TWENTY)
      //   return _nominalWording(nominal % TEN) + 'Belas ';
      // return _nominalWording(Math.floor(nominal / TEN)) + 'Puluh ' + _nominalWording(nominal % TEN);
    }

    if (nominal < ONE_THOUSAND) {
      if (nominal < UNIQUE_TWO_HUNDRED) return 'Seratus ' + _nominalWording(nominal % ONE_HUNDRED);
      return _nominalWording(Math.floor(nominal / ONE_HUNDRED)) + 'Ratus ' + _nominalWording(nominal % ONE_HUNDRED);
    }

    if (nominal < ONE_MILLION) {
      if (nominal < UNIQUE_TWO_THOUSAND) return 'Seribu ' + _nominalWording(nominal % ONE_THOUSAND);
      return _nominalWording(Math.floor(nominal / ONE_THOUSAND)) + 'Ribu ' + _nominalWording(nominal % ONE_THOUSAND);
    }

    if (nominal < ONE_BILLION) return _nominalWording(Math.floor(nominal / ONE_MILLION)) + 'Juta ' + _nominalWording(nominal % ONE_MILLION);
    return _nominalWording(Math.floor(nominal / ONE_BILLION)) + 'Miliar ' + _nominalWording(nominal % ONE_BILLION);
  };

  if (_isNominalValid(nominal)) return 'Nol Rupiah';
  return _nominalWording(nominal);
};

exports.moneyToString = moneyToString;

var validateEmail = function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

exports.validateEmail = validateEmail;

var validatePhone = function validatePhone(phone) {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return re.test(String(phone));
};

exports.validatePhone = validatePhone;

var capitalizeFirstCharacter = function capitalizeFirstCharacter(text) {
  var textArray = text.split(' ');
  textArray.map(function (text, index) {
    textArray[index] = text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
  });
  return textArray.join(' ');
};

exports.capitalizeFirstCharacter = capitalizeFirstCharacter;